import Web3 from "web3";
import factory from '../contracts/IUniswapV2Factory.json';
import pair from '../contracts/IUniswapV2Pair.json';

export const fetchUniswap = async () => {

    try {

        const web3 = new Web3('https://mainnet.infura.io/v3/4ae06169aaed42bcb04ee497ba90b107')

        const tokenAddress = '0xe31fe8478e8efc5416b750cf7a452e613d8b20b6' // Token address
        const WETHAddress = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'; // WETH address on Ethereum

        const uniswapFactoryContract = new web3.eth.Contract(factory.abi, factory.address);

        // Get token price in WETH
        const pairAddress = await uniswapFactoryContract.methods.getPair(tokenAddress, WETHAddress).call();
        const pairContract = new web3.eth.Contract(pair.abi, pairAddress);

        const reserves = await pairContract.methods.getReserves().call();
        const tokenReserve = reserves.reserve0;
        const WETHReserve = reserves.reserve1;

        const priceInWETH = tokenReserve / WETHReserve;
        
        return priceInWETH

    } catch (error) {
        console.log(error);
    }
};